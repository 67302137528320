#Home {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1%;
}

#title {
    font-size: 300%;
    margin-bottom: 2%;
}

#Divider {
    background-color: #FF0000;
    height: 5px;
    border-radius: 5px;
    width: 120%;
    margin-top: -0.5%;
}

#tagline {
    font-size: 200%;
    margin-top: 0.5%;
}

#AboutUs {
    height: 200px;
    width: 40%;
    background-color: #1E1E1E;
    text-align: center;
}

#AboutUs p {
    font-size: 130%;
}

#Logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
}

#Logo {
    margin-left: 4%;
    margin-right: 4%;
}